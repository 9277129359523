import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "When to Use a Extend / When to Use a Mixin",
  "date": "2014-11-23T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Harry Roberts wrote `}<a parentName="p" {...{
        "href": "http://csswizardry.com/2014/11/when-to-use-extend-when-to-use-a-mixin/"
      }}>{`a great post on CSS Wizardry`}</a>{` about the distinction between `}<inlineCode parentName="p">{`@extend`}</inlineCode>{` and `}<inlineCode parentName="p">{`@mixin`}</inlineCode>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Only use @extend when the rulesets that you are trying to DRY out are inherently and thematically related. Do not force relationships that do not exist: to do so will create unusual groupings in your project, as well as negatively impacting the source order of your code.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Use a mixin to either inject dynamic values into repeated constructs, or as a Sassy copy/paste which allows you to repeat the same group of declarations throughout your project whilst keeping a Single Source of Truth.`}</p>
    </blockquote>
    <p>{`I completely agree with it. In real life, that means I almost never use `}<inlineCode parentName="p">{`@extend`}</inlineCode>{`s. If you have a lot of elements that share the same styles for a reason, you should probably make it a class on its own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      